<template>
  <q-page class="column full-width window-height">
    <q-scroll-area
      class="full-width mask-overflow-b"
      ref="scrollArea"
      :style="pageStyle"
    >
      <div class="row items-center justify-center q-pb-md q-pt-xl">
        <div :class="contentClass">
          <img
            alt="Gardner White"
            src="~assets/logo.svg"
            :style="`width: 200px;`"
          />
        </div>
      </div>
      <template
        v-for="(content, index) in app.letter.content"
        :key="content._key"
      >
        <div
          :class="$q.screen.gt.sm ? 'scroll-section' : void 0"
          class="row items-center justify-center q-pb-xl"
        >
          <component
            :data="content"
            :index="index"
            :is="isComponent(content._type)"
          />
        </div>
      </template>
      <div class="full-width justify-center q-mb-xl q-pb-xl row">
        <q-btn
          color="dark"
          download
          :href="pdfLink"
          label="Download Resume"
          square
          target="_blank"
        />
      </div>
    </q-scroll-area>
    <q-page-sticky
      position="top-right"
      :offset="$q.screen.lt.md ? [12, 14] : [8, 22]"
    >
      <q-btn color="white" dense flat icon="menu" @click="onClick()" />
    </q-page-sticky>
  </q-page>
</template>

<script setup>
import { computed, nextTick, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useAppStore } from "src/stores/app";
import BodyComponent from "src/components/body/BodyComponent.vue";
import ProjectsComponent from "src/components/project/ProjectsComponent.vue";

defineOptions({ name: "IndexPage" });

const { t } = i18n.global;
const appStore = useAppStore();
const { app, contentClass, drawer } = storeToRefs(appStore);
const pageHeight = ref("100vh");
const pageStyle = computed(() => {
  return { minHeight: pageHeight.value };
});
const pdfLink = "/ChrisQuezada-CV.pdf";
const scrollArea = ref(null);

const isComponent = (type) => {
  return type === "bodyBlock" ? BodyComponent : ProjectsComponent;
};

const setScrollContainerStyle = () => {
  const container = scrollArea.value?.$el?.querySelector(
    ".q-scrollarea__container"
  );
  if (container) {
    container.style.minHeight = pageHeight.value;
    container.style.scrollSnapType = "y mandatory";
  }
};

const styleFn = (offset) => {
  pageHeight.value = offset ? `calc(100vh - ${offset}px)` : "100vh";
};

const onClick = () => {
  drawer.value = !drawer.value;
};

watch(pageHeight, async () => {
  await nextTick();
  setScrollContainerStyle();
});
</script>
